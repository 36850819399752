.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

@media screen and (max-width: 780px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
