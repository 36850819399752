.form__card {
  width: 40%;
  margin: auto;
}

.block {
  width: 100% !important;
}

.form-control {
  border: 1px solid #0e204e !important;
  outline: none !important;
}

.form {
  background-color: #0e204e;
  background-image: linear-gradient(315deg, #0e204e 0%, #28313b 74%);
  height: 100dvh;
}

.form__card {
  background-color: white;
}

@media screen and (max-width: 780px) {
  .form__card {
    width: 90%;
  }
}
