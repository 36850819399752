/* base classes */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

img {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
.text-main {
  font-family: "Nunito", sans-serif;
}

p,
span,
.text-sec,
input,
select,
textarea,
label,
button,a {
  font-family: "Josefin Sans", sans-serif;
}
