.footer {
  background-color: #020617;
  color: white;
  padding-bottom: 0.6rem;
}

.footer__links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__media {
  background-color: #020617;
  margin-right: 0.8rem;
  padding: 0.6rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
