.letter {
  margin: 4rem 0 0 0;
  color: #fff;
  background-color: #020617;
}

.letter__container {
  width: 80%;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.letter__title {
  text-align: center;
  margin: 2rem;
}

.letter__input {
  margin-bottom: 1rem;
}

.letter__input input {
  font-size: 1.1rem;
  padding: 0.4rem;
  border: none;
  outline: none;
  width: 100%;
}

@media screen and (max-width: 780px) {
  .letter__container {
    padding: 0.4rem;
  }

  .letter__title h1 {
    font-size: 1.4rem;
  }
}
